import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { Plugins } from '../plugins'

const Plu = () => {
    return (
        <L10nProvider>
            <Plugins />
        </L10nProvider>
    )
}

export default Plu;
