import React from 'react'
import useLocale from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';

const Plu = () => {
    return (
        <L10nProvider>
            <Plugins />
        </L10nProvider>
    )
}

export const Plugins = () => {
    const t = useLocale()
    return (
        <LayoutDefault>
            <section className={classes.sec}>
                <div className={classes.secBreadcrumbs}>
                    <span>{t('plugins.breadcrumbs.1')}</span>
                    <span>/</span>
                    <span>{t('plugins.breadcrumbs.2')}</span>
                </div>
                <h3 className={classes.secTitle}>
                    {t('plugins.description.p1')}
                </h3>
                <div className={classes.secDescription}>
                    <p>
                        {t('plugins.description.p2')}
                    </p>
                </div>
                <div className={classes.secBlock}>
                    <h5 className={classes.secSubtitle}>
                        {t('plugins.description.p3')}
                    </h5>
                    <div className={classes.secDescription}>
                        <p>
                            Sink-plugins, <span className={classes.textOrange}>{t('plugins.description.p4')}</span>* {t('plugins.description.p5')}
                        </p>
                        <p>
                            {t('plugins.description.p6')}
                        </p>
                        <p>
                            {t('plugins.description.p7')} <a
                            href="https://github.com/LogDoc-org/sdk/blob/main/src/main/java/org/logdoc/structs/LogEntry.java">LogEntry</a> -
                            {t('plugins.description.p8')}
                        </p>
                        <p>
                            {t('plugins.description.p9')}
                        </p>
                        <p>
                            {t('plugins.description.p10')}
                            <ul>
                                <li>
                                    <span className={classes.textOrange}>Syslog</span>. {t('plugins.description.p11')}
                                    Syslog.
                                </li>
                                <li>
                                    <span className={classes.textOrange}>Journald</span>. {t('plugins.description.p13')}
                                    Journald UDP.
                                </li>
                                <li>
                                    <span className={classes.textOrange}>Java logging</span>. {t('plugins.description.p14')} Java.
                                </li>
                                <li>
                                    <span className={classes.textOrange}>Python logging</span>. {t('plugins.description.p15')}
                                </li>
                                <li>
                                    <span className={classes.textOrange}>LogDoc logging</span>. {t('plugins.description.p16')}
                                </li>
                            </ul>

                            {t('plugins.description.p17')} sink-plugins <a
                            href="https://github.com/LogDoc-org/sink-plugins">{t('plugins.description.p18')}</a>.
                        </p>
                        <p>
                            {t('plugins.description.p19')} <a
                            href="https://github.com/LogDoc-org/sdk/blob/main/src/main/java/org/logdoc/sdk/SinkPlugin.java">оrg.logdoc.sdk.SinkPlugin</a>
                        </p>
                    </div>
                </div>
                <div className={classes.secBlock}>
                    <h5 className={classes.secSubtitle}>
                        {t('plugins.description.p20')} (Pipe-plugins)
                    </h5>
                    <div className={classes.secDescription}>
                        <p>
                            Pipe-plugins, <span className={classes.textOrange}>{t('plugins.description.p21')}</span>** {t('plugins.description.p22')}
                        </p>
                        <p>
                            {t('plugins.description.p23')}<br/>
                            {t('plugins.description.p24')}
                        </p>
                        <p>
                            {t('plugins.description.p25')}
                            <ul>
                                <li>
                                    <span className={classes.textOrange}>Emailer</span>. {t('plugins.description.p26')}
                                </li>
                                <li>
                                    <span className={classes.textOrange}>HttpCallback</span>. {t('plugins.description.p27')}
                                </li>
                                <li>
                                    <span className={classes.textOrange}>Telegramer</span>. {t('plugins.description.p28')}
                                </li>
                                <li>
                                    <span className={classes.textOrange}>Kafker</span>. {t('plugins.description.p29')}
                                </li>
                            </ul>

                            {t('plugins.description.p30')} pipe-plugins <a
                            href="https://github.com/LogDoc-org/pipe-plugins">{t('plugins.description.p31')}</a>.
                        </p>
                        <p>
                            {t('plugins.description.p32')} <a
                            href="https://github.com/LogDoc-org/sdk/blob/main/src/main/java/org/logdoc/sdk/PipePlugin.java">оrg.logdoc.sdk.PipePlugin</a>
                        </p>
                    </div>
                </div>
                <div className={classes.secDescription}>
                    <p>
                        <em>*{t('plugins.description.p33')}<br/>
                            {t('plugins.description.p34')}</em>
                    </p>
                    <p>
                        <em>**{t('plugins.description.p35')}<br/>
                            {t('plugins.description.p36')}<br/>
                            {t('plugins.description.p37')}
                        </em>
                    </p>

                </div>
            </section>
        </LayoutDefault>
    );
};

export default Plu;
